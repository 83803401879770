import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import {
  Maybe,
  Media,
  MediaForMediaListFragment,
  User,
  FolderGroupType,
  SaveMediaInput,
  NewMediaSubscriptionGQL,
  MediaBulkAction,
  MediaVisibilityType,
  MediaFiltersInput,
} from '@designage/gql';
import {
  NgbDropdownModule,
  NgbModal,
  NgbPopoverModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { DatatableRowActivationType, CeCalledFrom } from '@desquare/enums';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  SlidePanelService,
  FolderService,
  MediaService,
  SessionService,
} from '@desquare/services';
import { CloudinaryService } from '@desquare/services';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IDesignageDataTableColumns,
  IMediaFilterButtonGroupOutput,
  IMediaForm,
  IMediaForMediaList,
  IMediaVisibilityInfo,
} from '@desquare/interfaces';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '@desquare/environments';
import { bulkActionsUtil, stringToEnumUtil } from '@desquare/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { domConstants } from '@desquare/constants';
import { styleUtil } from '@desquare/utils';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  lastValueFrom,
  map,
  Observable,
  retry,
  startWith,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { CreativeEditorComponent } from '@designage/app/creative-editor/creative-editor/creative-editor.component';
import { MoveMediaFolderDialogComponent } from '@desquare/components/common/src/modals/move-media-folder-dialog.component';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SearchInputComponent } from '@desquare/components/common/src/search-input/search-input.component';
import { MediaFilterButtonGroupComponent } from '@designage/app/shared/media-filter-button-group/media-filter-button-group.component';
import { AngularSplitModule } from 'angular-split';
import { FolderExplorerComponent } from '@desquare/components/common/src/folder/folder-explorer/folder-explorer.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { CloudinaryModule } from '@cloudinary/ng';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { FileSizePipe } from '@desquare/components/common/src/pipe/file-size/file-size.pipe';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';

// /**
//  * These extended properties of the IMedia type doesn't really exist in the
//  * actual Media object coming from the backend (MediaForMediaListFragment), these
//  * are mainly for showing medias to the datatable, extended properties are merely computed
//  * or derived properties coming from the actual properties of the Media object (MediaForMediaListFragment)
//  * using the setMedias() function.
//  */
// interface IMedia extends MediaForMediaListFragment {
//   dimensions?: Maybe<string>;
//   downloadLink?: Maybe<string>;
//   readableType?: MediaReadableType;
// }

interface IFolderBreadCrumb {
  id: string | null;
  name: string;
}

const DEFAULT_FOLDER_PATH = 'root/';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularSplitModule,
    CloudinaryModule,
    NgbDropdownModule,
    NgbTooltip,
    NgbPopoverModule,
    MatButtonModule,
    MatMenuModule,
    LoaderComponent,
    DesignageDataTableComponent,
    SearchInputComponent,
    MediaFilterButtonGroupComponent,
    FolderExplorerComponent,
    TableDateTimeComponent,
    RouterOutlet,
    FileSizePipe,
    CloudinaryMediaComponent,
  ],
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
  providers: [FolderService, MediaService], // note: this is not sharing the same instance with the <app-folder-tree>
})
export class MediaListComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  @ViewChild(DesignageDataTableComponent)
  designageDataTable!: DesignageDataTableComponent;

  @Input() user!: Maybe<User>;
  @Input() isAssetSelection!: boolean;
  @Input() enableCheckbox = true;
  @Input() showBulkAction = true;
  @Input() showCreativeEditor = true;
  @Input() enableSlidePanel = true;
  @Output() selectedMediaList = new EventEmitter<Media[]>();
  @Output() selectedMedia = new EventEmitter<Media>();

  slidePanelService = inject(SlidePanelService);

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedMediaId = this.slidePanelService.getPanelComponentId();

  loaderMessage!: string;
  loading = false;
  desColumns: IDesignageDataTableColumns[] = [];
  medias: IMediaForMediaList[] = [];
  mediaList: Media[] = [];
  mediaSearchForm!: FormGroup;
  mediaActionsForm!: FormGroup;
  // searchText = '';
  datatableLoading = false;
  media!: Maybe<Media>;
  isUploadDialogOpen = false;
  total = 0;
  page = 1;
  pageSize = 10;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  mediaListActions = Object.values(MediaBulkAction);
  profileId!: Maybe<string>;
  headerText!: string;
  prevPage = 1;
  showReloadButton = false;
  externalPaging = false;

  bulkActionsUtil = bulkActionsUtil;

  getUserMediasSubscription?: Subscription;
  getProfileMediasSubscription?: Subscription;

  folderExplorer = false;

  /** limit to only images or only videos */
  @Input()
  mediaTypes = '';

  @Input()
  multiSelect = true;

  // slide panel variables
  visibilityModes: IMediaVisibilityInfo[] = [];

  globalSearch!: AbstractControl;

  get showVisibilityModes() {
    return this.visibilityModes.length > 1;
  }

  selectedFolderId$ = new BehaviorSubject<string | null>(null);
  getSelectedFolderId = () => this.selectedFolderId$.getValue();

  searchText$ = new BehaviorSubject<string>('');
  getSearchText = () => this.searchText$.getValue();

  selectedFolderFullPath: string = DEFAULT_FOLDER_PATH;
  // selectedFolderFullPath: string | null = null;
  folderBreadCrumbs: IFolderBreadCrumb[] = [];

  mediaList$!: Observable<IMediaForMediaList[]>;
  filteredMediaList$!: Observable<IMediaForMediaList[]>;
  mediaFilterButtonGroupOutput$ = new BehaviorSubject<
    IMediaFilterButtonGroupOutput[]
  >([]);

  folderTreeIds = computed(() => this.mediaService.folderIds());

  constructor(
    private toasterService: ToasterService,
    private encryptionService: EncryptionService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private currentUserService: CurrentUserService,
    private session: SessionService,
    private translateService: TranslateService,
    private cloudinaryService: CloudinaryService,
    private newMediaSubscription: NewMediaSubscriptionGQL,
    private folderService: FolderService,
    private mediaService: MediaService,
    private cdr: ChangeDetectorRef
  ) {}

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  get showButtons() {
    return this.profileId;
  }

  get bulkActionButtonStyle() {
    return styleUtil.getBulkActionButtonStyle(
      this.mediaActionsForm.controls.action.value
    );
  }

  get bulkActionButtonIsEnabled() {
    const action = this.mediaActionsForm.controls.action.value;

    return this.mediaList.length > 0 && action;
  }

  openUploadWidget() {
    this.cloudinaryService.getUploadWidget().open();
  }

  createMedia(mediatype: 'Design' | 'Video' = 'Design') {
    const modalRef = this.modalService.open(CreativeEditorComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });
    modalRef.componentInstance.calledFrom = CeCalledFrom.NEW;
    modalRef.componentInstance.initialSceneMode = mediatype;
    if (this.media) {
      const id = this.encryptionService.encrypt(this.media.id);
    }
  }

  ngOnDestroy() {
    this.cloudinaryService.destroyUploadWidget();
    this.getProfileMediasSubscription?.unsubscribe();
    this.getUserMediasSubscription?.unsubscribe();
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormState();
    this.initObservables();
    this.initSubscriptions();
  }

  initObservables() {
    const searchText$ = this.searchText$;

    const selectedFolderId$ = this.selectedFolderId$;

    const globalSearch$ = this.globalSearch.valueChanges.pipe(
      startWith(this.globalSearch.value)
    );

    const mediaVisibilityFilters$ = this.mediaFilterButtonGroupOutput$.pipe(
      map((filterList) =>
        filterList.length === 0
          ? undefined
          : filterList.map((filter) => filter.mediaVisibility)
      )
    );

    const resourceFilters$ = this.mediaFilterButtonGroupOutput$.pipe(
      map((filterList) =>
        filterList.length === 0
          ? undefined
          : filterList.map((filter) => filter.resourceType)
      )
    );

    const profileId = this.profileId!;

    this.mediaList$ = combineLatest({
      searchText: searchText$,
      selectedFolderId: selectedFolderId$,
      globalSearch: globalSearch$,
      mediaVisibilityFilters: mediaVisibilityFilters$,
      resourceFilters: resourceFilters$,
    }).pipe(
      switchMap(
        ({
          globalSearch,
          mediaVisibilityFilters,
          resourceFilters,
          searchText,
          selectedFolderId,
        }) => {
          const filters: MediaFiltersInput = {
            name: searchText,
            folderId: selectedFolderId,
            mediaVisibility: mediaVisibilityFilters,
            resourceType: resourceFilters,
          };

          let mediaList$: Observable<IMediaForMediaList[]>;
          this.datatableLoading = true;

          if (this.user) {
            mediaList$ = this.mediaService.getMediaByUser({
              userId: this.user.id,
              filters,
              globalSearch,
            });

            this.headerText = 'CONTENT_CONNECTED_TO_THIS_USER';
          } else if (profileId) {
            mediaList$ = this.mediaService.getMediaByProfile({
              profileId,
              filters,
              globalSearch,
            });

            this.headerText = 'CONTENT_CONNECTED_TO_THIS_PROFILE';

            this.initUploadWidget();
          } else {
            mediaList$ = EMPTY;
            this.datatableLoading = false;
          }

          this.cdr.detectChanges();

          return mediaList$;
        }
      ),
      tap((mediaList) => {
        this.datatableLoading = false;
        this.total = mediaList.length;
        // console.log('mediaList', mediaList);
      }),
      catchError((error) => {
        // TOFIX: apollo bug when one of the medias have a broken link to cloudinary
        // so far this only happens when we have a (missing) in the media list
        // https://github.com/apollographql/apollo-client/issues/7608
        // the link tells us its fixed in apollo/client v3.7.0
        // our current version is v3.3.20 (01-24-2023)
        this.datatableLoading = true;
        console.error(error);
        console.warn('Resetting query: wait for 2sec.');

        this.mediaService.resetQueries();

        throw new Error(error);
      }),
      retry({ count: 3, delay: 2000, resetOnSuccess: true })
    );
  }

  async initUploadWidget() {
    /* const folder = this.profileId
      ? `${FolderGroupType.Profile}/${this.profileId}`
      : `${FolderGroupType.User}/${this.user?.id}`;
      */

    const profileId = this.profileId;
    const folder = profileId
      ? `${FolderGroupType.Profile}/${profileId}`
      : `${FolderGroupType.User}/${this.user?.id}`; // this should never happen!
    const [folderGroupType, idFolder] = folder.split('/');
    const cloudinaryParams = environment.cloudinary;

    let queue = cloudinaryParams.uploadWidgetParameters.text.default.queue;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { title_uploading_with_counter, title, upload_more } = queue;

    queue = {
      title: await this.translateService.get(title).toPromise(),
      title_uploading_with_counter: await this.translateService
        .get(title_uploading_with_counter)
        .toPromise(),
      upload_more: await this.translateService.get(upload_more).toPromise(),
    };

    cloudinaryParams.uploadWidgetParameters.folder = folder;
    cloudinaryParams.uploadWidgetParameters.text.default.queue = queue;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.cloudinaryService.createUploadWidget(
      cloudinaryParams,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any, result: any) => {
        if (error) {
          this.toasterService.error('ERROR_CODE_6_5');
          return;
        }

        switch (result.event) {
          case 'success': {
            const {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              public_id,
              url,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              secure_url,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              original_filename,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              resource_type,
            } = result.info;
            const response: SaveMediaInput = {
              folder: idFolder,
              folderGroupType:
                stringToEnumUtil.getFolderGroupType(folderGroupType),
              name: original_filename,
              publicId: public_id,
              resourceType: stringToEnumUtil.getResourceType(resource_type),
              secureUrl: secure_url,
              url,
              dbFolderId: this.getSelectedFolderId(),
            };
            this.saveMedia(response);
            break;
          }
          case 'queues-start':
            this.toasterService.info('UPLOADING_MEDIA');
            break;
        }
      }
    );
  }

  initForm() {
    this.mediaSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
      globalSearch: [false],
    });
    this.globalSearch = this.mediaSearchForm.controls.globalSearch;

    this.mediaActionsForm = this.formBuilder.group({
      action: [null],
    });
  }

  initVariables() {
    this.profileId = this.session.profileId();

    this.desColumns = [
      {
        fieldName: 'publicId',
        name: 'THUMBNAIL',
        type: 'template',
        templateRef: 'thumbnail',
        visible: true,
        style:
          'display:flex;max-height: 120px;max-width: 120px;justify-content: center;',
        disableSorting: true,
      },
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'readableType',
        name: 'TYPE',
        type: 'string',
        visible: true,
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'metadata.bytes',
        name: 'FILE_SIZE',
        type: 'template',
        templateRef: 'fileSize',
        visible: true,
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'dimensions',
        name: 'DIMENSIONS',
        type: 'string',
        visible: true,
        style: 'max-width: 7.5rem;',
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
    ];

    this.pageSize = this.currentUserService.preferredPageSize;

    this.visibilityModes.push({
      visibility: MediaVisibilityType.Default,
      description: 'MEDIA_VISIBILITY_DEFAULT',
    });
    if (this.currentUserService.canEditTemplate) {
      this.visibilityModes.push({
        visibility: MediaVisibilityType.Template,
        description: 'MEDIA_VISIBILITY_TEMPLATE',
      });
    }
  }

  initSubscriptions() {
    if (this.profileId) {
      // TODO: handle case later
      // this.getMedia();
      this.initUploadWidget();
    }

    if (this.globalSearch) {
      this.subs.sink = this.globalSearch.valueChanges.subscribe((value) => {
        // note: this guard prevents globally fetching everything
        // if searchText value is empty and globalSearch state is true
        // then don't search
        if (!this.getSearchText() && value === true) return;

        this.search(this.getSearchText());
      });
    }
  }

  setFormState() {
    this.subs.sink =
      this.mediaSearchForm.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.resetPage();

          if (value > this.medias.length) {
            // TODO: handle case later
            // this.getMedia();
          }

          this.currentUserService.setPreferredPageSize(value);
        }
      );
  }

  cancelUpload(values: IMediaForm) {
    // TODO: test this
    const mediaIds = values.items.map(({ id }) => id);
    lastValueFrom(this.mediaService.deleteMedia(mediaIds));

    // values.items.forEach((item) => {
    //   this.subs.sink = this.deleteMediaGQL
    //     .mutate({
    //       id: item.id,
    //     })
    //     .subscribe(({ data }) => {
    //       if (data && data.deleteMedia.isSuccessful) {
    //         // this.getMedia();
    //         this.mediaService.refetchQueries();
    //       }
    //     });
    // });
  }

  navigateToMedia({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK) {
      const media: Media = row;
      if (media && media.id) {
        const encryptedMediaId = this.encryptionService.encrypt(media.id);

        if (this.currentUserService.canManageMedias) {
          this.router.navigate(['media/manage', encryptedMediaId]);
          // this.router.navigate(['manage', encryptedMediaId], {
          //   relativeTo: this.route,
          // });
        }
      }
    }
  }

  // onRowClickOld(args: IDatatableRowActivateArgs) {
  //   const { column, row, type } = args;
  //   if (column?.name !== '' && type === DatatableRowActivationType.CLICK) {
  //     if (!this.isAssetSelection) {
  //       this.navigateToMedia(args);
  //     } else {
  //       const media: Media = row;
  //       if (media?.id) {
  //         this.selectedMedia.emit(media);
  //         if (this.multiSelect === false) {
  //           this.selectedMediaList.emit([media]);
  //         }
  //       }
  //     }
  //   }
  // }
  onRowClick(media: Media) {
    if (!this.isAssetSelection) {
      this.slidePanelService.setPanelComponentId(media.id);
      const encryptedMediaId = this.encryptionService.encrypt(media.id);

      if (this.currentUserService.canManageMedias) {
        this.router.navigate(['media/manage', encryptedMediaId]);
      }
    } else {
      this.selectedMedia.emit(media);
      console.log('selectedMedia', media);

      if (this.multiSelect === false) {
        this.selectedMediaList.emit([media]);
        console.log('selectedMediaList', [media]);
      }
    }
  }

  onSelectedChange() {
    // this.mediaList = mediaList;
    this.selectedMediaList.emit(this.mediaList);
  }

  onPageChange({ page }: IDatatablePageChangeArgs) {
    this.prevPage = this.page;
    this.page = page;

    if (this.externalPaging) {
      if (
        this.page > this.medias.length / this.pageSize &&
        this.medias.length !== this.total
      ) {
        this.mediaService.refetchQueries();
        // this.getMedia();
      }
    }
  }

  search(searchText: string) {
    // this.searchText = searchText;
    this.searchText$.next(searchText);

    this.resetPage();
    // this.medias = [];
    // this.getMedia();
  }

  async saveMedia(cloudinaryResponse: SaveMediaInput) {
    await lastValueFrom(
      this.mediaService.saveMedia({
        ...cloudinaryResponse,
        dbFolderId: this.getSelectedFolderId(),
      })
    );
  }

  submitBulkAction() {
    const action = this.mediaActionsForm.controls.action.value;
    const ids = this.mediaList.map((x) => x.id);
    // const names = this.mediaList.map((x) => x.name);
    const currentSelectedFolderId = this.getSelectedFolderId();

    switch (action) {
      case MediaBulkAction.DeleteMedia:
        this.toasterService.info('DELETING_MEDIA');
        this.bulkDelete({
          selectedMedia: [...this.mediaList],
          currentSelectedFolderId,
        });
        break;

      case MediaBulkAction.MoveSelected:
        // this.toasterService.info('MOVING_MEDIA_FOLDER');
        this.bulkMoveMediaFolder(ids);
        break;
      default:
        throw new Error(`Action not supported, ${action}`);
    }
    this.designageDataTable.selection.clear();
    this.mediaList = [];
  }

  bulkActionClick(action: MediaBulkAction) {
    const ids = this.mediaList.map((x) => x.id);
    const currentSelectedFolderId = this.getSelectedFolderId();

    switch (action) {
      case MediaBulkAction.DeleteMedia:
        this.bulkDelete({
          selectedMedia: [...this.mediaList],
          currentSelectedFolderId,
        });
        break;

      case MediaBulkAction.MoveSelected:
        // this.toasterService.info('MOVING_MEDIA_FOLDER');
        this.bulkMoveMediaFolder(ids);
        break;
      default:
        throw new Error(`Action not supported, ${action}`);
    }
    this.designageDataTable.selection.clear();
    this.mediaList = [];
  }

  //Delete all selected  media lists
  async bulkDelete({
    selectedMedia,
    currentSelectedFolderId,
  }: {
    selectedMedia: MediaForMediaListFragment[];
    currentSelectedFolderId: string | null;
  }) {
    this.datatableLoading = true;
    await this.mediaService.openDeleteMediaDialog({
      mediaList: selectedMedia,
      mediaFolderId: currentSelectedFolderId,
    });
    this.datatableLoading = false;
    this.designageDataTable.selection.clear();
  }

  bulkMoveMediaFolder(mediaIds: string[]) {
    const modal = this.modalService.open(MoveMediaFolderDialogComponent, {
      backdrop: 'static',
      size: 'xl',
    });

    modal.componentInstance.selectedMedia = this.mediaList.map(
      ({ name, publicId, type }) => ({
        name,
        publicId,
        type,
      })
    );
    console.log('data to modal:', modal.componentInstance.selectedMedia);
    console.log('full Data:', this.mediaList);

    modal.result.then(
      (folderId: string | null) => {
        lastValueFrom(
          this.mediaService.moveMediaToFolder({ folderId, mediaIds })
        );
      },
      () => {}
    );
  }

  async onSelectFolderId(folderId: string) {
    this.selectedFolderId$.next(folderId);
    // this.selectedFolderId = folderId;

    // update breadcrumb list
    this.folderBreadCrumbs = await this.getFolderBreadCrumbs(folderId);

    // this will refetch media list and refresh the UI
    // since the selectedFolderId is changed so will the
    // results of the refetched media list
    // this.getMedia();

    // get retrieve full path of folder by id
    if (!this.profileId) return;
    const folderWithFullPath =
      await this.folderService.getProfileFoldersWithFullPath(this.profileId);
    const selectedFolder = folderWithFullPath.find(
      (folder) => folder.id === folderId
    );
    this.selectedFolderFullPath =
      DEFAULT_FOLDER_PATH + (selectedFolder?.fullPath ?? '');
    // this.selectedFolderFullPath = selectedFolder?.fullPath ?? null;

    // Check if folders if Folderexplorer should be visible by default
    this.folderExplorer = folderWithFullPath.length > 0;
  }

  async getFolderBreadCrumbs(folderId: string): Promise<IFolderBreadCrumb[]> {
    // initialize list with root breadcrumb
    let folderBreadCrumbs: IFolderBreadCrumb[] = [
      {
        id: null,
        name: this.translateService.instant('ROOT_FOLDER'),
      },
    ];

    // if folder id is root (folderId === null) return initial breadcrumb list
    if (folderId === null) return folderBreadCrumbs;

    const fullPathList = await this.folderService.getFullPathListById(folderId);
    if (!fullPathList) return folderBreadCrumbs;

    // concat breadcrumb list to the initial breadcrumb list
    folderBreadCrumbs = folderBreadCrumbs.concat(
      fullPathList.map(({ id, name }) => {
        return {
          id,
          name,
        };
      })
    );

    return folderBreadCrumbs;
  }

  onFilterOutput(event: IMediaFilterButtonGroupOutput[]) {
    this.mediaFilterButtonGroupOutput$.next(event);
  }

  resetPage() {
    this.page = 1;
  }
}
