import {
  Input,
  ViewEncapsulation,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import CreativeEditorSDK, * as cesdkJs from '@cesdk/cesdk-js';
import {
  Folder,
  FolderGroupType,
  FolderType,
  GetMediaForMediaManagePageGQL,
  Maybe,
  Media,
  MediaVisibilityType,
  MetadataInput,
  SaveMediaFromCloudGQL,
  SaveMediaInput,
  UpdateMediaGQL,
} from '@designage/gql';
import {
  CloudinaryService,
  CurrentUserService,
  EncryptionService,
  FolderService,
  MediaService,
  ToasterService,
} from '@desquare/services';
import {
  getAssetNormalThumbUrl,
  stringToEnumUtil,
  timeout,
} from '@desquare/utils';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
  NgbProgressbarModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  ICloudinaryUploadResponse,
  IZoneResolution,
} from '@desquare/interfaces';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FontStyle, FontWeight } from '@cesdk/cesdk-js';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CeCalledFrom } from '@desquare/enums';
import { SourcesService } from '../ce-services/sources.service';
import { getFonts } from './staticFonts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { FolderExplorerComponent } from '@desquare/components/common/src/folder/folder-explorer/folder-explorer.component';

const lic =
  'eyJhbGciOiAiUlMyNTYiLCJ0eXAiOiAiSldUIn0=.ewogICJ2ZXJzaW9uIjogMSwKICAiZXhwIjogMjUzMjc3MzQ0NywKICAic3ViIjogIkRlU3F1YXJlIiwKICAidHlwZSI6ICJQcm9kdWN0aW9uIiwKICAicHJvZHVjdFZlcnNpb24iOiAxLAogICJwbGF0Zm9ybXMiOiBbIioiXQp9.pGd35gqY+dC0e0WSkUu7qFEglDSTJTgQj9ZIMAcAs7UNckBfQ0jx3TXYznfGpAVKelYiUx9snJQGN8U08RXCNOBk4FlOsMtykwcOl8oS4YwirgSs8FexAb4z310J7pE4MF/THCs+bG11DSKn33AUoim/VReNimhn5Ga1gHczhU8gj4BpaXEYVXQNn8/2KbZa+OWVyfTZV1pyjanTU21IXrDqzEJtgspQ8RysjJ6EkVz6oUQA0aJHGP4kWP5ou7VjxRxcXiHr+bkwZYbx2S3Z98zA+PtX5QtcQVJXwfoZSq8oZOUCVa/0Mf+WNL/x0mVmeUActzFB9gp9vJUqgzuRDmYa+9aKcogpKqxLtYhXEPjW1Q7oZdqVQbHfzpMmgP+IKMddMoL7aUi2+nn8hfNE3FjLJWiysp/hnuU2KQzoJqNkVgSXZBJJXr2uA4qDx6evRPeQvsej/5wVRV02dwx6cFJLPttvScGz7GemlOrHTcnGmzjeUb7TVy6044VhJGjB';

interface ICEFont {
  weight: FontWeight;
  style: FontStyle;
  fontURL: string;
}
interface IFont extends ICEFont {
  family: string;
}
interface IFontFamily {
  family: string;
  fonts: IFont[];
}
interface IFontTree {
  [id: string]: IFontFamily;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbTooltip,
    FolderExplorerComponent,
    NgbProgressbarModule,
  ],
  selector: 'creative-editor',
  templateUrl: './creative-editor.component.html',
  styleUrls: ['./creative-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FolderService],
})
export class CreativeEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly CeCalledFrom = CeCalledFrom;
  /** if true CE has been opened from inside a playlist */
  @Input() calledFrom!: CeCalledFrom | null;
  @Input() initialSceneMode: cesdkJs.SceneMode = 'Design';
  @Input() configRole: cesdkJs.RoleString = 'Creator';
  @Input() mediaId?: string;
  // @Output() closeModal = new EventEmitter();
  @Input() inputImgUrl?: string;
  @Input() inputVideoUrl?: string;
  @Input() previewResolution?: IZoneResolution | null = null;

  @ViewChild('ce_container') containerRef: ElementRef = {} as ElementRef;
  @ViewChild('onCloseModal') onCloseModalEl!: NgbModalRef;
  @ViewChild('onSaveModal') onSaveModalEl!: ElementRef;
  @ViewChild('onSaveAsModal') onSaveAsModalEl!: ElementRef;
  @ViewChild('onSaveAsTemplateModal') onSaveAsTemplateModalEl!: ElementRef;

  canEditTemplate = false;
  currentMediaIsTemplate = new BehaviorSubject<boolean>(false);

  editor!: CreativeEditorSDK;
  ceBlock!: cesdkJs.BlockAPI;
  ceAsset!: cesdkJs.AssetAPI;
  ceEditor!: cesdkJs.EditorAPI;

  editorHeaderName: string | undefined = '';
  sceneResolution = { width: 1920, height: 1080 };
  saveProgressPercent = 0;
  saveProgressTotal!: number;
  saveProgressFrames!: number;
  // renderMode: 'video'|'image' = 'determinate';

  /**
   *  warning: media variable is being mutated throughout the life cycle of the component
   *  - use initialMedia instead to get the media when it was initially retrieved
   */
  media?: Maybe<Media>;
  mediaHasChanged = false;
  unsavedChanges = false;
  initialMedia: Maybe<Media>;

  dateValue = new Date();

  profileFolders?: Folder[];

  unsubscribeEditor!: () => void;

  get showToggleRoleButton() {
    // show this button only when media is template or playlist
    return (
      this.canEditTemplate &&
      this.currentMediaIsTemplate.value &&
      this.configRole !== 'Creator'
    );
  }

  // dynamic labels
  CREATIVE_EDITOR_SAVEAS = 'CREATIVE_EDITOR_SAVEAS';
  CREATIVE_EDITOR_SAVEAS_TT = 'CREATIVE_EDITOR_SAVEAS_TT';

  constructor(
    public editorModal: NgbActiveModal,
    private modalService: NgbModal,
    private cloudinaryService: CloudinaryService,
    private currentUserService: CurrentUserService,
    private getMediaGql: GetMediaForMediaManagePageGQL,
    private updateMediaGQL: UpdateMediaGQL,
    private saveMediaGQL: SaveMediaFromCloudGQL,
    private encryptionService: EncryptionService,
    private folderService: FolderService,
    private translateService: TranslateService,
    private mediaService: MediaService,
    private router: Router,
    private sourcesService: SourcesService
  ) {
    this.currentMediaIsTemplate.pipe(takeUntilDestroyed()).subscribe(() => {
      this.setDynamicButtonLabels();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribeEditor();
  }

  async ngAfterViewInit() {
    await this.tryGetMedia();
    await this.getProfileFolders();
    this.canEditTemplate = this.currentUserService.canEditTemplate;
    await this.initCreativeEditor();
    this.unsubscribeEditor = this.initEditorSubscription();
    // template Adopter mdde
    if (this.calledFrom === CeCalledFrom.PLAYLIST) {
      this.currentMediaIsTemplate.next(this.isTemplate());
      if (this.currentMediaIsTemplate.value) {
        this.switchRole('Adopter');
      }
    }
    this.decideCreateNewOrEdit();
    this.unsavedChanges = false;
  }

  async initCreativeEditor() {
    /*
    if (
      this.calledFrom === CeCalledFrom.MEDIALIST ||
      this.calledFrom === CeCalledFrom.NEW
    ) {
      this.configRole = 'Creator';
    } 
    */
    const config = await this.getConfiguration();
    // console.log('config: ', config); // DEBUG

    this.editor = await CreativeEditorSDK.create(
      this.containerRef.nativeElement,
      config
    ).then(async (instance) => {
      // add default asset source (Stickers, Vectorpath, filter.lut, filter.duotone)
      instance.addDefaultAssetSources({
        baseURL: undefined,
        excludeAssetSourceIds: [],
      });

      // add template sources
      this.sourcesService.loadTemplateSource(
        instance.engine,
        this.initialSceneMode,
        (asset: cesdkJs.AssetResult): any =>
          instance.engine.scene.loadFromURL(asset.meta?.uri!)
      );

      // add image and video asset sources
      this.sourcesService.addSources(
        instance.engine,
        this.initialSceneMode,
        this.getFolderIds,
        this.profileFolders!
      );

      // Remove page numbers
      instance.engine.editor.setSettingBool('page/title/show', false);
      instance.engine.editor.setSettingBool('page/dimOutOfPageAreas', true);

      // Set resolution of the page to incoming resolulion from the playlist preview setting
      const setCanvasResolution = (previewResolution: IZoneResolution) => {
        const pageBlock = instance.engine.scene.getPages();
        instance.engine.block.resizeContentAware(
          pageBlock,
          previewResolution.width,
          previewResolution.height
        );
      };

      console.log('CE ROLE', this.configRole);
      // Create from Scene
      if (
        this.media?.source?.url
        //          &&
        //         this.calledFrom !== CeCalledFrom.PLAYLIST
      ) {
        console.log('media has scene url', this.media.source.url);

        await instance.loadFromURL(this.media.source.url);
        return instance;
      }

      // Create from Image
      if (this.initialSceneMode === 'Design' && this.inputImgUrl) {
        await instance.engine.scene.createFromImage(
          this.inputImgUrl.replace('http://', 'https://')
        );
        if (this.calledFrom === CeCalledFrom.PLAYLIST && this.previewResolution)
          setCanvasResolution(this.previewResolution);

        return instance;
      }

      // Create from Video
      if (this.initialSceneMode === 'Video' && this.inputVideoUrl) {
        await instance.engine.scene.createFromVideo(
          this.inputVideoUrl.replace('http://', 'https://')
        );

        if (this.calledFrom === CeCalledFrom.PLAYLIST && this.previewResolution)
          setCanvasResolution(this.previewResolution);

        return instance;
      }

      // Create empty scene
      this.initialSceneMode === 'Design'
        ? await instance.createDesignScene()
        : await instance.createVideoScene();
      return instance;
    });

    this.ceBlock = this.editor.engine.block;
    this.ceAsset = this.editor.engine.asset;
    this.ceEditor = this.editor.engine.editor;

    // deselect all blocks
    const selectedElements = this.ceBlock.findAllSelected();
    selectedElements.forEach((selectedElement) => {
      this.ceBlock.setSelected(selectedElement, false);
    });
  }

  /** subscribe to editor events */
  initEditorSubscription() {
    /** 
    Check every time something changes
    to see if we should make save dirty and
    if the scene is contains a placeholder template 
    */
    return this.ceEditor.onHistoryUpdated(() => {
      this.unsavedChanges = true;
      this.checkIfMediaIsTemplate();
    });
  }

  checkIfMediaIsTemplate() {
    this.currentMediaIsTemplate.next(this.isTemplate());
  }

  /** executed just at save or save as actions when template status is known */
  decideVisibility(action: 'SAVE' | 'SAVEAS') {
    if (this.calledFrom === CeCalledFrom.PLAYLIST && action === 'SAVE') {
      // default to Playlist, if user clicks on saveas then we must choose
      this.currentMediaVisibility = MediaVisibilityType.Playlist;
    } else {
      if (this.isTemplate()) {
        this.currentMediaVisibility = MediaVisibilityType.Template;
      } else {
        this.currentMediaVisibility = MediaVisibilityType.Default;
      }
    }
  }

  /** executed automatically when template status changes */
  setDynamicButtonLabels() {
    let SAVEAS = 'CREATIVE_EDITOR_SAVEAS';
    let SAVEAS_TT = 'CREATIVE_EDITOR_SAVEAS_TT';

    // TODO: if calledFrom !== CeCalledFrom.PLAYLIST would be nice to tell user that the save_as button makes the picture visibile in library
    if (this.calledFrom !== CeCalledFrom.PLAYLIST) {
      if (
        this.currentMediaIsTemplate.value ||
        this.calledFrom === CeCalledFrom.NEW
      ) {
        SAVEAS = 'CREATIVE_EDITOR_SAVEAS_TEMPLATE';
        SAVEAS_TT = 'CREATIVE_EDITOR_SAVEAS_TEMPLATE_TT';
      }
    }
    this.CREATIVE_EDITOR_SAVEAS = SAVEAS;
    this.CREATIVE_EDITOR_SAVEAS_TT = SAVEAS_TT;
  }

  /** checks if any block is a placeholder */
  isTemplate() {
    const blocksIds = this.ceBlock.findAll();
    for (const id of blocksIds) {
      if (this.ceBlock.isPlaceholderEnabled(id)) {
        // this is a template!
        return true;
      }
    }
    return false;
  }

  async tryGetMedia() {
    if (this.mediaId) {
      const { data } = await lastValueFrom(
        this.getMediaGql.fetch({ id: this.mediaId })
      );

      // save original media before the changes for reverting
      this.initialMedia = data.media;

      this.setMedia(data.media);
      console.log('load media', this.media);
    }
  }

  setMedia(media: Maybe<Media>) {
    this.media = media;
    this.editorHeaderName = media?.name;

    // if editing a plain image (no source id) from library force create a new media
    if (this.calledFrom != CeCalledFrom.PLAYLIST && !media?.source?.publicId) {
      this.saveEnabled = false; // this is a plain image, user must use "Save As"
      this.newMediaName = `${media?.name} - Edit`;
    } else {
      this.saveEnabled = true;
    }
  }

  decideCreateNewOrEdit() {
    if (
      this.media &&
      this.media.visibility !== MediaVisibilityType.Playlist &&
      this.calledFrom === CeCalledFrom.PLAYLIST
    ) {
      // save as a new hidden media (only visible for this PLAYLIST)
      this.newMediaName = `${this.media?.name} - Edit`;
      this.media = undefined;
      this.mediaId = undefined;

      // MARCO: maybe obsolete ince change role is now just an editor action
      // TODO: currently the 2 commented code below causes issues
      // note: uncommenting this causes a bug when switching roles
      // steps to reproduce:
      // 1. switch role
      // 2. try editing in creator mode (role)
      // blocks couldn't be interacted

      // then force create a new Media!
    }
  }

  async getConfiguration() {
    const gridBackgroundType: 'contain' | 'plain' = 'contain';
    const gridItemHeight: 'auto' | 'square' = 'auto';

    const ceBaseUrl = `${location.origin}/assets/ce`;
    // const ceBaseUrl = `${environment.urls.designageApp}/assets/ce`;

    const config: cesdkJs.Configuration = {
      license: lic,
      // use initialScene if any, initialImage otherwise
      theme: 'dark', // 'light' or 'dark'
      role: this.configRole, //'Adopter' or 'Creator'
      featureFlags: {
        singlePageMode: true,
      },
      presets: {
        typefaces: await getFonts(this.currentUserService.currentProfile),
      },
      baseURL: ceBaseUrl,
      ui: {
        pageFormats: this.getPageFormats(),
        // scale: 'normal', // 'normal' or 'large'
        elements: {
          blocks: { '//ly.img.ubq/page': { manage: false, format: true } }, // https://img.ly/docs/cesdk/ui/guides/elements/#pages
          navigation: {
            // position: 'top',
            // position: NavigationPosition.Top, // 'top' or 'bottom'
            action: {
              close: false,
              back: false,
              load: false,
              save: false,
              export: false,
              download: false,
            },
          },
          panels: {
            settings: false,
            inspector: {
              show: true,
            },
          },
          // view: this.configRole === 'Creator' ? 'advanced' : 'default',
          view: 'advanced',
          dock: {
            iconSize: 'normal', // 'large' or 'normal'
            hideLabels: false, // false or true
            groups: [
              {
                id: 'image-templates',
                entryIds: ['ly.img.template'],
              },
              {
                id: 'ly.img.defaultGroup', // string
                showOverview: false, // true or false
              },
            ],
            defaultGroupId: 'ly.img.defaultGroup', // string
          },
          libraries: {
            insert: {
              entries: (defaultEntries) => {
                return [
                  ...this.sourcesService.initAssetLibraries(
                    this.initialSceneMode,
                    this.profileFolders
                  ),
                  ...this.getDefaultContent(defaultEntries),
                ];
              },
              floating: true, // true or false
              autoClose: true, // true or false
            },
            replace: {
              entries: (defaultEntries) => {
                return [
                  ...this.sourcesService.initAssetLibraries(
                    this.initialSceneMode,
                    this.profileFolders,
                    false
                  ),
                  ...this.getDefaultContent(defaultEntries),
                ];
              },
              floating: true, // true or false
              autoClose: false, // true or false
            },
          },
        },
      },
      i18n: {
        en: {
          // 'libraries.ly.img.video.templates.label': 'Video Templates',
          'libraries.ly.img.template.label': 'Image Templates',
          'libraries.Images.label': 'Image library',
          'libraries.Videos.label': 'Video library',
          'libraries.profile.label': 'Profile Library',
        },
      },
      callbacks: {
        // onUpload: async (file, onProgress, context) => {
        //   console.log('FILE: ', file); // DEBUG
        //   console.log('onProgress: ', onProgress); // DEBUG
        //   console.log('CONTEXT: ', context); // DEBUG
        //   // get profile
        //   const profileId = this.currentUserService.getCurrentProfileId();
        //   if (!profileId)
        //     return Promise.reject(
        //       new Error('onUpload callback: no profile id')
        //     );
        //   const cloudinaryResponse = await this.cloudinaryService
        //     .directUploadPromise(file)
        //     .catch((reason) => new Error(reason));
        //   if (cloudinaryResponse instanceof Error) {
        //     return Promise.reject(cloudinaryResponse);
        //   }
        //   const {
        //     public_id,
        //     url,
        //     secure_url,
        //     original_filename,
        //     resource_type,
        //   } = cloudinaryResponse;
        //   const saveMediaResponse = await lastValueFrom(
        //     this.mediaService.saveMedia({
        //       folder: profileId,
        //       folderGroupType: stringToEnumUtil.getFolderGroupType(
        //         FolderGroupType.Profile
        //       ),
        //       name: original_filename,
        //       publicId: public_id,
        //       resourceType: stringToEnumUtil.getResourceType(resource_type),
        //       secureUrl: secure_url,
        //       url,
        //       dbFolderId:
        //         context?.group === 'root' ? undefined : context?.group,
        //       // note: the field above handles which folder the saved media will
        //       // go, we can utilize the groupId or assetSourceId in the future
        //     })
        //   );
        //   if (!saveMediaResponse.data)
        //     return Promise.reject(
        //       new Error('onUpload callback: no save response data')
        //     );
        //   console.log('saveMediaResponse: ', saveMediaResponse); // DEBUG
        //   const { isSuccessful, media } = saveMediaResponse.data.saveMedia;
        //   if (!isSuccessful || !media)
        //     return Promise.reject(
        //       new Error('onUpload callback: save media gql failed')
        //     );
        //   const { id, secureUrl, name, source } = media;
        //   this.editor.refetchAssetSources();
        //   return Promise.resolve({
        //     id,
        //     label: { en: name },
        //     meta: {
        //       uri: source?.url as string,
        //       thumbUri: getAssetNormalThumbUrl(secureUrl),
        //     },
        //   });
        // },
        /*
         */
      },
    };
    return config;
  }

  getDefaultContent(
    input: cesdkJs.UserInterfaceElements.AssetLibraryEntry[]
  ): cesdkJs.UserInterfaceElements.AssetLibraryEntry[] {
    const filters = [
      'ly.img.template',
      'ly.img.image',
      'ly.img.video',
      'ly.img.audio',
      'ly.img.upload',
    ];
    const output = input.filter((x) => {
      return filters.indexOf(x.id) == -1 ? true : false;
    });
    // console.log('output: ', output); // DEBUG

    return output;
  }

  /**
   * - this function is for getGroups() in imageLibraryConfig.assetSource
   * - this function runs everytime the image library asset is opened
   * - this returns single folderId in a list due to imgly's group concept
   *   where assets (media) can be in multiple groups, however in our case
   *   a media can only belong to one folder
   *
   * @returns a promise of a folderId in a list
   */
  getFolderIds: () => Promise<string[]> = async () => {
    const profileFolders =
      this.profileFolders ?? (await this.getProfileFolders());

    const profileFolderIds = profileFolders.map(({ id }) => id);

    profileFolderIds.unshift('root');
    // note: 'root' will be the id for profile images
    // that are in the root folder (folderId = null)

    // console.log('findFolders: ', profileFolderIds); // DEBUG

    return profileFolderIds;
  };

  getPageFormats(): { [id: string]: cesdkJs.PageFormatDefinition } | undefined {
    let defaultResult: { [id: string]: cesdkJs.PageFormatDefinition };

    defaultResult = {
      // PageFormatDefinition
      'Full HD': {
        default: true,
        width: 1920,
        height: 1080,
        unit: 'Pixel', // DesignUnit.Pixel
      },
    };
    if (this.previewResolution) {
      // defaultResult['Full HD'].default = false;
      return {
        'Playlist Preview': {
          default: false,
          width: this.previewResolution.width,
          height: this.previewResolution.height,
          unit: 'Pixel', // DesignUnit.Pixel
        },
        ...defaultResult,
      };
    }

    return defaultResult;
  }

  /** completely close editor without updating */
  destroyEditor() {
    this.editor?.dispose();
    // this.modalService.dismissAll();

    this.editorModal.close(this.mediaHasChanged ? this.media : undefined);
  }

  async onSelectFolderId(folderId: string) {
    this.editingMediaFolderId = folderId;
  }

  tryCloseEditor() {
    if (this.unsavedChanges) {
      this.closeModal();
    } else {
      this.destroyEditor();
    }
  }
  /** show close popup confirmation */
  closeModal() {
    this.modalService.open(this.onCloseModalEl, {
      size: 'sm',
    });
  }

  openUploadWidget() {
    this.cloudinaryService.getUploadWidget().open();
  }

  saveSceneResponse?: ICloudinaryUploadResponse;
  saveMediaResponse?: ICloudinaryUploadResponse;
  saveCanceled = false;

  cancelSave() {
    this.saveCanceled = true;
  }
  saveEnabled = true;
  newMediaName = '';
  editingMediaName = '';
  editingMediaFolderId = '';

  /** value based on template status, calledFrom value and save/saveas action */
  currentMediaVisibility = MediaVisibilityType.Default;

  async save(close: boolean = false) {
    this.decideVisibility('SAVE');
    return this.doSave(close);
  }
  /** show dialog to save to media library (visibility = default or template) */
  async saveAsPopup() {
    this.decideVisibility('SAVEAS');
    // init new folder
    this.editingMediaFolderId = this.initialMedia?.folderId || '';
    this.editingMediaName = this.newMediaName;

    const modal = this.modalService.open(this.onSaveAsModalEl, {
      size: 'lg',
    });
  }

  /** called after showSaveAsPopup, does save-to-library */
  async doSaveAs() {
    if (this.editingMediaName) {
      // force create a new file
      this.media = undefined;
      this.mediaId = undefined;
      this.newMediaName = this.editingMediaName;
      await this.doSave();
    }
  }
  /** show popup saving info, saves and updates */
  async doSave(close: boolean = false) {
    if (!(this.media || this.newMediaName)) {
      this.saveAsPopup();
      return;
    }

    const scene = await this.editor.engine.scene.saveToString();

    // prepare save statuses
    this.saveCanceled = false;
    this.saveSceneResponse = undefined;
    this.saveMediaResponse = undefined;

    // show saving popup
    const modal = this.modalService.open(this.onSaveModalEl, {
      size: 'lg',
    });

    // in parallel upload scene and media, then wait for responses and update db
    await this.saveScene(scene);
    this.saveProgressPercent = 20;
    this.initialSceneMode === 'Design'
      ? await this.savePng()
      : await this.saveMp4();
    await this.updateMediaInLibrary(modal, close);
  }

  async saveScene(scene: string) {
    const publicId = this.media?.source?.publicId || undefined;
    const blobScene = new Blob([scene], {
      type: 'text/plain',
      // type: 'application/octet-stream', //'text/plain',
    });

    await this.cloudinaryService.directUpload(
      'raw',
      blobScene,
      publicId,
      undefined,
      (error, result) => {
        this.afterSaveSceneCallback(error, result);
      }
    );
  }

  async savePng() {
    const pageBlock = this.editor.engine.block.findByKind('page')[0];
    const blob = await this.editor.engine.block.export(
      pageBlock,
      cesdkJs.MimeType.Png
    );
    console.log('cloudinaryService.upload', blob);
    await this.cloudinaryService.directUpload(
      'image',
      blob,
      undefined, // do not overwrite
      undefined,
      (error, result) => {
        this.afterSaveMediaCallback(error, result);
        this.saveProgressPercent = 100;
      }
    );
  }

  async saveMp4() {
    const videoOptions = {
      /**
       * Determines the encoder feature set and in turn the quality, size and speed of the encoding process.
       *
       * @defaultValue 77 (Main)
       */
      h264Profile: 77,
      /**
       * Controls the H.264 encoding level. This relates to parameters used by the encoder such as bit rate,
       * timings and motion vectors. Defined by the spec are levels 1.0 up to 6.2. To arrive at an integer value,
       * the level is multiplied by ten. E.g. to get level 5.2, pass a value of 52.
       *
       * @defaultValue 52
       */
      h264Level: 52,
      /**
       * The time offset in seconds of the scene's timeline from which the video will start.
       *
       * @defaultValue 0
       */
      // timeOffset: 0,
      /**
       * The duration in seconds of the final video.
       *
       * @defaultValue The duration of the scene.
       */
      // duration: 10,
      /**
       * The target framerate of the exported video in Hz.
       *
       * @defaultValue 30
       */
      // framerate: 30,
      /**
       * An optional target width used in conjunction with target height.
       * If used, the block will be rendered large enough, that it fills the target
       * size entirely while maintaining its aspect ratio.
       */
      // targetWidth: 1280,
      /**
       * An optional target height used in conjunction with target width.
       * If used, the block will be rendered large enough, that it fills the target
       * size entirely while maintaining its aspect ratio.
       */
      // targetHeight: 720,
    };
    const sceneBlock = this.editor.engine.block.findByKind('page')[0];

    const progressCallback = (
      renderedFrames: number,
      encodedFrames: number,
      totalFrames: number
    ) => {
      this.saveProgressPercent = Math.round(
        20 + (renderedFrames / totalFrames) * 80
      );
      this.saveProgressFrames = encodedFrames;
      this.saveProgressTotal = totalFrames;
      // console.log('encodedFrames', this.saveProgressPercent); // DEBUG
    };
    const blob = await this.editor.engine.block.exportVideo(
      sceneBlock,
      cesdkJs.MimeType.Mp4,
      progressCallback,
      videoOptions
    );
    // console.log('cloudinaryService.upload', blob); // DEBUG
    await this.cloudinaryService.directUpload(
      'video',
      blob,
      undefined, // do not overwrite
      undefined,
      (error, result) => {
        this.afterSaveMediaCallback(error, result);
      }
    );
  }

  getDefaultMetaFromCloudy(): MetadataInput {
    return {
      height: this.saveMediaResponse?.height,
      width: this.saveMediaResponse?.width,

      bytes: this.saveMediaResponse?.bytes,
      resourceType: this.saveMediaResponse?.resource_type,
      format: this.saveMediaResponse?.format,
      uploadedAt: this.saveMediaResponse?.created_at,
      duration: this.saveMediaResponse?.duration,
    };
  }
  async updateMediaInLibrary(savingModal: NgbModalRef, close: boolean = false) {
    while (
      !this.saveSceneResponse ||
      !this.saveMediaResponse ||
      this.saveCanceled
    ) {
      await timeout(500);
    }
    console.log('files uploaded');
    if (this.saveCanceled) {
      // close saving info modal
      savingModal.close();
      return;
    }
    // step 3: save media, with source and new png url
    if (this.media?.id) {
      // update
      const input = {
        input: {
          id: this.media?.id,
          name: this.media.name,
          publicId: this.saveMediaResponse?.public_id,
          url: this.saveMediaResponse?.secure_url,
          source: {
            publicId: this.saveSceneResponse?.public_id,
            url: this.saveSceneResponse?.secure_url,
          },
          visibility: this.currentMediaVisibility,
          defaultMeta: this.getDefaultMetaFromCloudy(),
        },
      };

      // console.log('updating media', input);
      const { data } = await lastValueFrom(this.updateMediaGQL.mutate(input));
      this.media = data?.updateMedia.media;
    } else {
      // media is null, create a new one
      // console.log('this.saveMediaResponse', this.saveMediaResponse as any);

      const { public_id, url, secure_url, resource_type } =
        this.saveMediaResponse;

      this.saveProgressPercent = 100;

      const input: SaveMediaInput = {
        folder: this.currentUserService.getCurrentProfileId(),
        folderGroupType: FolderGroupType.Profile,
        name: this.newMediaName,
        publicId: public_id,
        resourceType: stringToEnumUtil.getResourceType(resource_type),
        secureUrl: secure_url,
        url,
        source: {
          publicId: this.saveSceneResponse.public_id,
          url: this.saveSceneResponse.secure_url,
        },
        visibility: this.currentMediaVisibility,
        dbFolderId: this.editingMediaFolderId,
        defaultMeta: this.getDefaultMetaFromCloudy(),
      };
      const { data } = await lastValueFrom(
        this.saveMediaGQL.mutate({
          input,
        })
      );
      this.setMedia(data?.saveMedia.media as Media);
    }
    // close saving info modal
    savingModal.close();

    if (close) {
      this.editor?.dispose();
    }
    // close editor and return new media
    console.log('updated media', this.media);
    this.mediaHasChanged = true;
    this.unsavedChanges = false;
    if (close) {
      this.editorModal.close(this.media);
    }
  }

  /** collect response from Cloudinary after Scene file has been saved */
  async afterSaveSceneCallback(
    error: any,
    response: ICloudinaryUploadResponse
  ) {
    this.saveSceneResponse = response;
    if (error) console.error('error from cloudinary', error);
  }

  /** collect response from Cloudinary after Media file has been saved */
  async afterSaveMediaCallback(
    error: any,
    response: ICloudinaryUploadResponse
  ) {
    this.saveMediaResponse = response;
    console.log('cloudinary upload resp', response);
  }

  async getProfileFolders(): Promise<Folder[]> {
    const profileId = this.currentUserService.currentProfile?.id;
    if (!profileId) return [];

    const profileFolders =
      await this.folderService.getProfileFoldersWithFullPath(profileId);

    // filter out trash folder and its descendants
    const filteredFolders = this.folderService.deepFilterFolderList(
      profileFolders,
      (folder) => folder.folderType !== FolderType.Trash
    );

    // save profile folders
    this.profileFolders = filteredFolders;

    // console.log('folders', this.profileFolders); //DEBUG
    return filteredFolders;
  }

  getFolderTags(folderId: Maybe<string>) {
    const folder = folderId
      ? this.profileFolders?.find((x) => x.id === folderId)
      : undefined;
    return folder ? folder.fullPath?.split('/') : [];
  }

  async switchRole(role: cesdkJs.RoleString = 'Creator') {
    this.configRole = role;
    this.ceEditor.setRole(role);
  }

  openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.headerText = this.translateService.instant(
      'CREATIVE_EDITOR_EDIT_TEMPLATE_CONFIRMATION_HEADER'
    );
    modalRef.componentInstance.bodyText = this.translateService.instant(
      'CREATIVE_EDITOR_EDIT_TEMPLATE_CONFIRMATION_PROMPT'
    );
    modalRef.componentInstance.dismissButtonText = 'CANCEL';
    modalRef.componentInstance.closeButtonText = 'CLOSE_WITHOUT_SAVING';
    modalRef.componentInstance.useDangerButton = true;

    return modalRef.result.then(
      (value) => {
        return true;
      },
      () => {
        return false;
      }
    );
  }

  // TODO: reconsider this
  navigateToTemplate() {
    console.log('this.mediaId', this.initialMedia!);

    const encryptedMediaId = this.encryptionService.encrypt(
      this.initialMedia?.id!
    );

    if (this.currentUserService.canManageMedias) {
      this.destroyEditor();
      this.router.navigate(['media/manage', encryptedMediaId]);
    }
  }
}
